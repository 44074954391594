function submitGIT( e ) {
	e.preventDefault();

	document.querySelector( '#git button[type=submit]' ).disabled = true;
	document.querySelector( '#git-error' ).classList.add( 'hidden' );

	let formInputs = e.target.getElementsByTagName( 'input' );
	let formData = new FormData( );

	for( let input of formInputs ) {
		formData.append( input.name, input.value );
	}

	let messages = e.target.getElementsByTagName( 'textarea' );

	for( let message of messages ) {
		formData.append( message.name, message.value );
	}


	fetch( 'git.php',
		{
			method: 'post',
			body: formData
		}).then( response => response.json() )
	.then( data => {
		if( 'success' ==  data.status ) {
			document.querySelector( '#git' ).classList.add( 'hidden' );
			document.querySelector( '#git-success' ).classList.remove( 'hidden' );
		} else {
			document.querySelector( '#git-error' ).classList.remove( 'hidden' );
			document.querySelector( '#git button[type=submit]' ).disabled = false;
		}
	})
	.catch( error => {
		document.querySelector( '#git-error' ).classList.remove( 'hidden' );
		document.querySelector( '#git button[type=submit]' ).disabled = false;
	});
}


document.getElementById( 'git' ).addEventListener( 'submit', submitGIT );
